<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">

      <a-form-model-item label="卡号" prop="cardNum" >
        <a-input v-model="form.cardNum" placeholder="请输入卡号" />
      </a-form-model-item>
      <a-form-model-item label="类型" prop="type" >
        <a-select placeholder="请选择" v-model="form.type" >
          <a-select-option :value="0">加卡</a-select-option>
          <a-select-option :value="1">减卡</a-select-option>
        </a-select>
      </a-form-model-item>

      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {   addLockCard } from '@/api/village/lock'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',

      // 表单参数
      form: {
        lockId: null,

        cardNum: null,

        type: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        cardNum: [
          { required: true, message: '请输入卡号', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择类型', trigger: 'blur' }
        ],
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        lockId: null,
        cardNum: null,
        type: null,
      }
      // this.$nextTick(() => {
      //   if(this.$refs.form){
      //     this.$refs.form.resetFields()
      //   }
      // })
    },
    /** 新增按钮操作 */
    handleAdd (lockId) {
      this.reset()
      this.formType = 1
      this.open = true
      this.form.lockId = lockId
      this.formTitle = '加卡减卡'
    },

    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          addLockCard(this.form).then(response => {
            this.$message.success(
              '操作成功',
              3
            )
            this.open = false
            this.$emit('ok')
          }).finally(() => {
            this.submitLoading = false
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
