import request from '@/utils/request'


// 查询小区锁列表
export function listLock(query) {
  return request({
    url: '/user/village-locks/list',
    method: 'get',
    params: query
  })
}

// 查询小区锁分页
export function pageLock(query) {
  return request({
    url: '/user/village-locks/page',
    method: 'get',
    params: query
  })
}

// 查询小区锁详细
export function getLock(data) {
  return request({
    url: '/user/village-locks/detail',
    method: 'get',
    params: data
  })
}

// 新增小区锁
export function addLock(data) {
  return request({
    url: '/user/village-locks/add',
    method: 'post',
    data: data
  })
}

// 修改小区锁
export function updateLock(data) {
  return request({
    url: '/user/village-locks/edit',
    method: 'post',
    data: data
  })
}

// 删除小区锁
export function delLock(data) {
  return request({
    url: '/user/village-locks/delete',
    method: 'post',
    data: data
  })
}
export function addLockCard(data) {
  return request({
    url: '/user/village-locks/addLockCard',
    method: 'post',
    data: data
  })
}